<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-btn color="primary" class="mr-2" v-on:click="CreateProductsblock" style="margin-top: 15px"> Create </v-btn>
        <v-btn depressed color="error" v-on:click="DeleteProductsblock" style="margin-right: 15px; margin-top: 15px"> Delete </v-btn>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details> </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        v-model="selected"
        show-select
        :items-per-page="-1"
        @click:row="EditRow"
      >
        <template v-slot:[`item.image`]="props">
          <v-img :src="'data:image/jpeg;base64,' + props.item.image" class="grey darken-4" max-height="30" max-width="50" contain></v-img>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="CreateProductsblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Create Productsblock</span>
          </v-card-title>
          <v-img :src="'data:image/jpeg;base64,' + Productsblock.image" class="grey darken-4" max-height="170" contain></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              label="image"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field v-model="Productsblock.name1" color="teal" label="name1"> </v-text-field>
            <v-text-field v-model="Productsblock.name2" color="teal" label="name2"> </v-text-field>
            <v-text-field v-model="Productsblock.name3" color="teal" label="name3"> </v-text-field>
            <v-text-field v-model="Productsblock.name4" color="teal" label="name4"> </v-text-field>
            <v-text-field v-model="Productsblock.name5" color="teal" label="name5"> </v-text-field>
            <div>date</div>
            <vue-pikaday v-model="Productsblock.date" color="teal" id="date" label="date" :options="pikadayOptions" />
            <hr />
            <v-textarea v-model="Productsblock.shorttext1" acolor="teal" label="shorttext1"> </v-textarea>
            <v-textarea v-model="Productsblock.shorttext2" acolor="teal" label="shorttext2"> </v-textarea>
            <v-textarea v-model="Productsblock.shorttext3" acolor="teal" label="shorttext3"> </v-textarea>
            <v-textarea v-model="Productsblock.shorttext4" acolor="teal" label="shorttext4"> </v-textarea>
            <v-textarea v-model="Productsblock.shorttext5" acolor="teal" label="shorttext5"> </v-textarea>
            <v-textarea v-model="Productsblock.longtext1" acolor="teal" label="longtext1"> </v-textarea>
            <v-textarea v-model="Productsblock.longtext2" acolor="teal" label="longtext2"> </v-textarea>
            <v-textarea v-model="Productsblock.longtext3" acolor="teal" label="longtext3"> </v-textarea>
            <v-textarea v-model="Productsblock.longtext4" acolor="teal" label="longtext4"> </v-textarea>
            <v-textarea v-model="Productsblock.longtext5" acolor="teal" label="longtext5"> </v-textarea>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn color="primary" class="mr-2" v-on:click="CreateProductsblock(Productsblock)"> Save </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close"> Cancel </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>

    <!-- Edit Productsblock -->
    <v-dialog v-model="editProductsblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Edit Productsblock</span>
          </v-card-title>
          <v-img :src="'data:image/jpeg;base64,' + Productsblock.image" class="grey darken-4" max-height="170" contain></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              label="image"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field v-model="Productsblock.name1" color="teal" label="name1"> </v-text-field>
            <v-text-field v-model="Productsblock.name2" color="teal" label="name2"> </v-text-field>
            <v-text-field v-model="Productsblock.name3" color="teal" label="name3"> </v-text-field>
            <v-text-field v-model="Productsblock.name4" color="teal" label="name4"> </v-text-field>
            <v-text-field v-model="Productsblock.name5" color="teal" label="name5"> </v-text-field>
            <div>date</div>
            <vue-pikaday v-model="Productsblock.date" color="teal" id="date" label="date" :options="pikadayOptions" />
            <hr />
            <v-textarea v-model="Productsblock.shorttext1" acolor="teal" label="shorttext1"> </v-textarea>
            <v-textarea v-model="Productsblock.shorttext2" acolor="teal" label="shorttext2"> </v-textarea>
            <v-textarea v-model="Productsblock.shorttext3" acolor="teal" label="shorttext3"> </v-textarea>
            <v-textarea v-model="Productsblock.shorttext4" acolor="teal" label="shorttext4"> </v-textarea>
            <v-textarea v-model="Productsblock.shorttext5" acolor="teal" label="shorttext5"> </v-textarea>
            <v-textarea v-model="Productsblock.longtext1" acolor="teal" label="longtext1"> </v-textarea>
            <v-textarea v-model="Productsblock.longtext2" acolor="teal" label="longtext2"> </v-textarea>
            <v-textarea v-model="Productsblock.longtext3" acolor="teal" label="longtext3"> </v-textarea>
            <v-textarea v-model="Productsblock.longtext4" acolor="teal" label="longtext4"> </v-textarea>
            <v-textarea v-model="Productsblock.longtext5" acolor="teal" label="longtext5"> </v-textarea>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn color="primary" class="mr-2" v-on:click="Update(Productsblock)"> Update </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close"> Cancel </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios").default;
import "@netcz/vue-pikaday";
export default {
  name: "Productsblocks",
  data: () => ({
    selected: [],
    search: "",
    image: "",
    CreateProductsblockModal: false,
    editProductsblockModal: false,
    Productsblock: {
      // Пока под вопросом
      // id: null,
      image: "",
      name1: "",
      name2: "",
      name3: "",
      name4: "",
      name5: "",
      date: "",
      shorttext1: "",
      shorttext2: "",
      shorttext3: "",
      shorttext4: "",
      shorttext5: "",
      longtext1: "",
      longtext2: "",
      longtext3: "",
      longtext4: "",
      longtext5: "",
    },

    headers: [
      {
        text: "Image",
        align: "start",
        sortable: false,
        value: "image",
      },
      {
        text: "Name1",
        align: "start",
        sortable: false,
        value: "name1",
      },
      {
        text: "Name2",
        align: "start",
        sortable: false,
        value: "name2",
      },
      {
        text: "Name3",
        align: "start",
        sortable: false,
        value: "name3",
      },
      {
        text: "Name4",
        align: "start",
        sortable: false,
        value: "name4",
      },
      {
        text: "Name5",
        align: "start",
        sortable: false,
        value: "name5",
      },
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "date",
      },
      {
        text: "ShortText1",
        align: "start",
        sortable: false,
        value: "shorttext1",
      },
      {
        text: "ShortText2",
        align: "start",
        sortable: false,
        value: "shorttext2",
      },
      {
        text: "ShortText3",
        align: "start",
        sortable: false,
        value: "shorttext3",
      },
      {
        text: "ShortText4",
        align: "start",
        sortable: false,
        value: "shorttext4",
      },
      {
        text: "ShortText5",
        align: "start",
        sortable: false,
        value: "shorttext5",
      },
      {
        text: "LongText1",
        align: "start",
        sortable: false,
        value: "longtext1",
      },
      {
        text: "LongText2",
        align: "start",
        sortable: false,
        value: "longtext2",
      },
      {
        text: "LongText3",
        align: "start",
        sortable: false,
        value: "longtext3",
      },
      {
        text: "LongText4",
        align: "start",
        sortable: false,
        value: "longtext4",
      },
      {
        text: "LongText5",
        align: "start",
        sortable: false,
        value: "longtext5",
      },
    ],
    items: [],
    config: "",
    pikadayOptions: {
      format: "YYYY-MM-DD",
    },
  }),

  created: function () {
    this.getProductsblock();
  },
  // mounted: {},
  methods: {
    EditRow(e) {
      this.editProductsblockModal = true;
      this.Productsblock = {
        id: e.id,
        image: e.image,
        name1: e.name1,
        name2: e.name2,
        name3: e.name3,
        name4: e.name4,
        name5: e.name5,
        date: e.date,
        shorttext1: e.shorttext1,
        shorttext2: e.shorttext2,
        shorttext3: e.shorttext3,
        shorttext4: e.shorttext4,
        shorttext5: e.shorttext5,
        longtext1: e.longtext1,
        longtext2: e.longtext2,
        longtext3: e.longtext3,
        longtext4: e.longtext4,
        longtext5: e.longtext5,
      };
    },
    getNameValue: function (key) {
      if (key !== undefined) {
        let name = this.items.find((x) => x.name === key);
        console.log(this.items);
        if (name !== undefined) {
          console.log(name["value" + this.langKey]);
          return name["value" + this.langKey];
        }
        return key;
      }
    },
    async getProductsblock() {
      await this.$store.dispatch("getAllProductsblocks");
      this.items = await this.$store.getters.getProductsblocks;
    },
    async Update(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let responseData = false;
      axios
        .post(this.config + "/UpdateProductsblock", item)
        .then((response) => {
          alert(response.data ? "Update Succsess" : "Update error");
          responseData = response.data;
          this.Close();
          this.UpdateAfter(responseData, item);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    async UpdateAfter(responseData, item) {
      if (responseData) {
        let data = await this.getProductsblockById(item.id);
        let index = this.items.findIndex((x) => x.id == item.id);
        this.items.splice(index, 1);
        if (data) {
          this.items.push(data);
        }
      }
    },
    async Save(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.post(this.config + "/SaveProductsblock", item);
      return response.data;
    },
    async CreateProductsblock(item) {
      if (!this.CreateProductsblockModal) {
        this.CreateProductsblockModal = true;
        this.image = "";
      } else {
        let response = await this.Save(item);

        if (response != null) {
          let data = await this.getProductsblockById(response);
          if (data) {
            this.items.push(data);
          }

          this.Productsblock = {
            id: null,
            name1: "",
            image: [],
            date: "",
            shorttext1: "",
            longtext1: "",
          };
          this.Close();
        } else {
          alert("Save error");
        }
      }
    },
    async getProductsblockById(id) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.get(this.config + "/GetProductsblock/" + id);
      return response.data;
    },
    async DeleteProductsblock() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      if (this.selected.length > 0) {
        let response = await axios.post(this.config + "/DeleteProductsblock", this.selected);
        for (let key in response.data) {
          if (response.data[key]) {
            let index = this.items.findIndex((x) => x.id === key);
            console.log(index);
            console.log(this.items[index]);
            this.items.splice(index, 1);
          }
        }
      }
    },
    Close() {
      this.CreateProductsblockModal = false;
      this.editProductsblockModal = false;
      this.Productsblock = {
        id: null,
        name1: "",
        image: "",
        date: "",
        shorttext1: "",
        longtext1: "",
      };
    },
    async Preview_image(e) {
      this.image = e;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.Productsblock.image = e.target.result.replace("data:", "").replace(/^.+,/, "");
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>
<style>
#date {
  background-color: rgb(235, 235, 235);
  width: 100%;
}
</style>
