<template>
  <component :is="component" />
</template>

<script>
import Textblock from "./Blocks/Textblock.vue";
import Pictureblock from "./Blocks/Pictureblock.vue";
import Newsblock from "./Blocks/Newsblock.vue";
import Articleblock from "./Blocks/Articleblock.vue";
import Licenseblock from "./Blocks/Licenseblock.vue";
import Partnerblock from "./Blocks/Partnerblock.vue";
import Workblock from "./Blocks/Workblock.vue";
import Productsblock from "./Blocks/Productsblock.vue";
export default {
  name: "dynamic",
  props: ["component"],
  components: {
    Textblock,
    Pictureblock,
    Newsblock,
    Articleblock,
    Licenseblock,
    Partnerblock,
    Workblock,
    Productsblock,
  },
};
</script>
